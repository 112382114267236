// core version + navigation, pagination modules:
import Swiper from 'swiper';
import {Navigation, Scrollbar, EffectCards} from 'swiper/modules';

// eslint-disable-next-line no-unused-vars
const swiper = new Swiper('.swiper', {
    modules: [Navigation, Scrollbar, EffectCards],
    spaceBetween: 10,
    loop: true,
    effect: 'cards',
    slidesPerView: 1,
    centeredSlides: true,

    scrollbar: {
        el: '.swiper-scrollbar',
    },

    navigation: {
        nextEl: '.js-swiper-button-next',
        prevEl: '.js-swiper-button-prev',
    },
});
